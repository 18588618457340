<template>
	<section class="content">
		<div class="card-back w-100">
			<div class="card card-primary card-mx-auto">
				<div class="nav border-bottom">
					<div class="nav-item cursor-pointer" @click="changeMenu('shipper')">
						<h6 class="nav-link m-2 font-weight-bold" :class="(tabmenu == 'shipper' ? 'active text-primary' : 'text-secondary')">Data Shipper</h6>
					</div>
					<div class="nav-item cursor-pointer" @click="changeMenu('verification')">
						<h6 class="nav-link m-2 font-weight-bold" :class="(tabmenu == 'verification' ? 'active text-primary' : 'text-secondary')">Verifikasi KTP</h6>
					</div>
					<div class="nav-item cursor-pointer" @click="changeMenu('notes')">
						<h6 class="nav-link m-2 font-weight-bold" :class="(tabmenu == 'notes' ? 'active text-primary' : 'text-secondary')">Catatan Shipper</h6>
					</div>
					<div class="nav-item cursor-pointer" @click="changeMenu('logs')" v-if="false">
						<h6 class="nav-link m-2 font-weight-bold" :class="(tabmenu == 'logs' ? 'active text-primary' : 'text-secondary')">Riwayat Perubahan</h6>
					</div>
				</div>
				<form class="card-body redesign" :hidden="!(tabmenu == 'shipper')" @submit.prevent="submitShipper">
					<div class="h4 pt-3 pb-2">Data Shipper</div>
					<div class="grid grid-auto-flow-column  g-col-2 rounded border pt-3" :class="( form_shipper.marking == 'blacklist' || form_shipper.marking == 'suspect' ? 'g-row-7' : 'g-row-8' )">
						<div class="form-group px-3">
							<div class="row">
								<div class="col">
									<label>Nama Shipper</label>
								</div>
							</div>
							<div class="input-group mb-3">
								<input type="text" class="form-control border-right-0" v-model="form_shipper.display_name" :class="{ 'is-invalid': false }" />
								<div class="input-group-append align-items-center border-left-0" style="border: 2px solid #ced4da;border-radius: 0 0.375rem 0.375rem 0;padding-right: 0.75rem;">
									<span class="h-fit badge" :class="( 'badge-' + (form_shipper.shipper_type == 1 ? 'agen' : 'cod') )">bos{{ form_shipper.shipper_type == 1 ? 'Agen' : 'COD' }}</span>
								</div>
							</div>
						</div>
						<div class="form-group px-3">
							<div class="row">
								<div class="col">
									<label>Email</label>
								</div>
							</div>
							<input type="text" class="form-control" v-model="form_shipper.email" :class="{ 'is-invalid': false }" />
						</div>
						<div class="form-group px-3">
							<div class="row">
								<div class="col">
									<label>Nomor HP</label>
								</div>
							</div>
							<input type="tel" class="form-control" v-model="form_shipper.kontak_hp" :class="{ 'is-invalid': false }" />
						</div>
						<div class="form-group px-3">
							<div class="row">
								<div class="col">
									<label>Bank</label>
								</div>
							</div>
							<v-select :options="listBank" :reduce="(option) => option.id" v-model="form_shipper.bank_id" :class="{ 'is-invalid': false }" hide-selected>
								<template #search="{attributes, events}">
									<input class="vs__search" v-bind="attributes" v-on="events" :class="{ 'is-invalid': false }" />
								</template>
							</v-select>
						</div>
						<div class="form-group px-3">
							<div class="row">
								<div class="col">
									<label>Rekening</label>
								</div>
							</div>
							<input type="number" class="form-control" v-model="form_shipper.bank_rekening" :class="{ 'is-invalid': false }" />
						</div>
						<div class="form-group px-3">
							<div class="row">
								<div class="col">
									<label>Atas Nama</label>
								</div>
							</div>
							<input type="text" class="form-control" v-model="form_shipper.bank_atasnama" :class="{ 'is-invalid': false }" />
						</div>
						<div class="form-group px-3 gy-2">
							<div class="row">
								<div class="col">
									<label>Alamat</label>
								</div>
							</div>
							<textarea class="form-control" v-model="form_shipper.alamat" :class="{ 'is-invalid': false }" style="height: 60%;resize: none;"></textarea>
							<div class="icheck-material-orange w-100">
								<input type="checkbox" id="active" v-model="form_shipper.active" />
								<label for="active" class="form-check-label w-100 font-weight-bold">{{ form_shipper.active ? '' : 'Not' }} Active</label>
							</div>
						</div>
						<div class="form-group px-3">
							<div class="row">
								<div class="col">
									<label>Terakhir Login</label>
								</div>
							</div>
							<div class="form-control" :class="{ 'is-invalid': false }">{{ getDate(form_shipper.last_login, 'HH:mm') }}</div>
						</div>
						<div class="form-group px-3">
							<div class="row">
								<div class="col">
									<label>Tanggal Bergabung</label>
								</div>
							</div>
							<div class="form-control" :class="{ 'is-invalid': false }">{{ getDate(form_shipper.joined_on) }}</div>
						</div>
						<div class="form-group px-3">
							<div class="row">
								<div class="col">
									<label>Estimasi Jumlah Kiriman</label>
								</div>
							</div>
							<v-select :options="shipmentAmount" :reduce="(option) => option.id" v-model="form_shipper.shipment_amount" :class="{ 'is-invalid': false }" hide-selected>
								<template #search="{attributes, events}">
									<input class="vs__search" v-bind="attributes" v-on="events" :class="{ 'is-invalid': false }" />
								</template>
							</v-select>
						</div>
						<div class="form-group px-3">
							<div class="row">
								<div class="col">
									<label>Tipe Member</label>
								</div>
							</div>
							<v-select :options="listMember" :reduce="(option) => option.id" v-model="form_shipper.membership_type_id" :class="{ 'is-invalid': false }" hide-selected>
								<template #search="{attributes, events}">
									<input class="vs__search" v-bind="attributes" v-on="events" :class="{ 'is-invalid': false }" />
								</template>
							</v-select>
						</div>
						<div class="form-group px-3">
							<div class="row">
								<div class="col">
									<label>CS/Sales</label>
								</div>
							</div>
							<v-select :options="listCS" :reduce="(option) => option.id" v-model="form_shipper.usercs_id" @search="searchCS" hide-selected>
								<template slot="no-options">Cari nama CS/Sales</template>
								<template slot="option" slot-scope="option">
									<div class="d-center">{{ option.label }}</div>
								</template>
								<template slot="selected-option" slot-scope="option">
									<div class="selected d-center">{{ option.label }}</div>
								</template>
								<template #search="{attributes, events}">
									<input class="vs__search" v-bind="attributes" v-on="events" :class="{ 'is-invalid': false }" />
								</template>
							</v-select>
						</div>
						<div class="form-group px-3">
							<div class="row">
								<div class="col">
									<label>Status Suspect</label>
								</div>
							</div>
							<select class="form-control" v-model="form_shipper.marking" :class="{ 'is-invalid': false }">
								<option v-for="marking in listMarking" :value="marking.id" :hidden="marking.hidden">{{ marking.label }}</option>
							</select>
						</div>
						<div class="form-group px-3" v-if="form_shipper.marking == 'blacklist' || form_shipper.marking == 'suspect'">
							<div class="row">
								<div class="col">
									<label class="text-capitalize">Alasan {{ form_shipper.marking }}</label>
								</div>
							</div>
							<select class="form-control" v-model="form_shipper.marking_reason" :class="{ 'is-invalid': false }">
								<option v-for="reason in listReason" :value="reason.id">{{ reason.label }}</option>
							</select>
						</div>
						<div class="form-group px-3" :class="( form_shipper.marking == 'blacklist' || form_shipper.marking == 'suspect' ? 'grid-row-1' : 'gy-2' )">
							<div class="row">
								<div class="col">
									<label>Akses Kurir</label>
								</div>
							</div>
							<v-select :options="listCourier" :reduce="(option) => option.courier_id" label="nama" v-model="form_shipper.courier_id" :class="{ 'is-invalid': false }" multiple>
								<template #search="{attributes, events}">
									<input class="vs__search" v-bind="attributes" v-on="events" :class="{ 'is-invalid': false }" />
								</template>
							</v-select>
						</div>
					</div>
					<div v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
						<b>Please correct the following error(s):</b>
						<ul>
							<li v-for="error in errors" v-bind:key="error">{{ error }}</li>
						</ul>
						<button type="button" class="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="row justify-content-end pt-3">
						<div class="col-12 col-sm-auto">
							<button type="submit" class="btn btn-primary btn-block font-weight-bold h-100 py-3 px-5" :disabled="processing">
								Simpan Perubahan
								<span v-if="processing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							</button>
						</div>
					</div>
				</form>
				<div class="card-body redesign" :hidden="!(tabmenu == 'verification')">
					<div class="h4 pt-3 pb-2">Data Verifikasi KTP</div>
					<div class="grid grid-auto-flow-column g-row-4 g-col-12 rounded border mb-3 pt-3">
						<div class="gx-7 form-group px-3">
							<div class="row">
								<div class="col">
									<label>Nama Lengkap</label>
								</div>
							</div>
							<div class="form-control" :class="{ 'is-invalid': false }">{{ form_ktp.nama }}</div>
						</div>
						<div class="gx-7 form-group px-3">
							<div class="row">
								<div class="col">
									<label>NIK</label>
								</div>
							</div>
							<div class="form-control" :class="{ 'is-invalid': false }">{{ form_ktp.nik }}</div>
						</div>
						<div class="gx-7 form-group px-3">
							<div class="row">
								<div class="col">
									<label>Nomor HP</label>
								</div>
							</div>
							<div class="form-control" :class="{ 'is-invalid': false }">{{ form_ktp.nomor }}</div>
						</div>
						<div class="gx-7 form-group px-3">
							<div class="row">
								<div class="col">
									<label>Email</label>
								</div>
							</div>
							<div class="form-control" :class="{ 'is-invalid': false }">{{ form_ktp.email }}</div>
						</div>
						<div class="gx-7 form-group px-3 grid-row-1">
							<div class="row">
								<div class="col">
									<label>Alamat</label>
								</div>
							</div>
							<div class="form-control" :class="{ 'is-invalid': false }">{{ !form_ktp.ktp_alamat ? '\u2e3a' : form_ktp.ktp_alamat }}</div>
						</div>
						<div class="gx-5 gy-2 form-group px-3">
							<div class="row">
								<div class="col">
									<label>Foto KTP</label>
								</div>
							</div>
							<div class="position-relative rounded bg-dark cursor-pointer" :class="{ 'is-invalid': false }" v-on:click="showImage()">
								<img :src="form_ktp.image_ktp" class="w-100" alt="Shipper belum verifikasi" style="height: 8.5rem;opacity: 0.5;object-fit: cover;" />
								<img :src="getImage('show')" class="position-absolute top-50 start-50 translate-middle" style="height: 2.5rem;" />
							</div>
						</div>
						<div class="gx-5 gy-2 form-group px-3">
							<div class="row">
								<div class="col">
									<label>Foto Selfie dengan KTP</label>
								</div>
							</div>
							<div class="position-relative rounded bg-dark cursor-pointer" :class="{ 'is-invalid': false }" v-on:click="showImage('Selfie dengan')">
								<img :src="form_ktp.image_selfie" class="w-100" alt="Shipper belum verifikasi" style="height: 8.5rem;opacity: 0.5;object-fit: cover;" />
								<img :src="getImage('show')" class="position-absolute top-50 start-50 translate-middle" style="height: 2.5rem;" />
							</div>
						</div>
					</div>
					<div class="h4 pt-3 pb-2">Informasi Pengajuan</div>
					<div class="grid grid-auto-flow-column g-row-6 g-col-2 rounded border pt-3">
						<div class="form-group px-3">
							<div class="row">
								<div class="col">
									<label>Tanggal Pengajuan Verifikasi</label>
								</div>
							</div>
							<div class="form-control" :class="{ 'is-invalid': false }">{{ getDate(form_request.ktp_verify_created) }}</div>
						</div>
						<div class="form-group px-3">
							<div class="row">
								<div class="col">
									<label>Status Approval</label>
								</div>
							</div>
							<div class="form-control font-weight-bold" :class="'text-' + statusKtp(form_request.ktp_verify).class">{{ statusKtp(form_request.ktp_verify).label }}</div>
						</div>
						<div class="form-group px-3">
							<div class="row">
								<div class="col">
									<label>Tanggal Sign Up</label>
								</div>
							</div>
							<div class="form-control" :class="{ 'is-invalid': false }">{{ getDate(form_request.tanggal_signup) }}</div>
						</div>
						<div class="form-group px-3">
							<div class="row">
								<div class="col">
									<label>Tanggal {{ form_ktp.ktp_verify == 3 ? 'Reject' : 'Approval' }}</label>
								</div>
							</div>
							<div class="form-control" :class="{ 'is-invalid': false }">{{ form_ktp.ktp_verify == 2 ? '\u2e3a' : getDate(form_request.ktp_verify_on) }}</div>
						</div>
						<div class="form-group px-3">
							<div class="row">
								<div class="col">
									<label>{{ form_ktp.ktp_verify == 3 ? 'Rejected' : 'Approved' }} by</label>
								</div>
							</div>
							<div class="form-control" :class="{ 'is-invalid': false }">{{ form_ktp.ktp_verify == 2 ? '\u2e3a' : form_request.admin }}</div>
						</div>
						<div class="form-group px-3">
							<div class="row justify-content-between">
								<div class="col">
									<label>Model Template</label>
								</div>
								<div class="col text-right">
									<label class="text-primary cursor-pointer" @click="showDialogTemplate()">Lihat Model <i class="fi fi-rr-angle-right"></i></label>
								</div>
							</div>
							<div class="form-control" :class="{ 'is-invalid': false }">{{ form_ktp.image_template ? form_ktp.image_template : '\u2e3a' }}</div>
						</div>
						<div class="gy-2 form-group px-3" v-if="form_ktp.ktp_verify == 2">
							<div class="position-relative h-100">
								<div class="row">
									<div class="col">
										<label>Approval Pengajuan</label>
									</div>
								</div>
								<div class="form-control bg-grey h-auto" :class="{ 'is-invalid': false }">
									<div class="row">
										<div class="col">
											<button class="btn btn-success btn-block" @click="approveVerification" :disabled="processing">
												Approve
												<span v-if="processing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
											</button>
										</div>
										<div class="col">
											<button class="btn btn-danger btn-block" @click="rejectVerification" :disabled="processing">
												Reject
												<span v-if="processing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
											</button>
										</div>
									</div>
								</div>
								<button class="btn btn-outline-primary position-absolute bottom-0 end-0" v-if="history_request.length > 0" :disabled="processing">
									Lihat History Pengajuan <i class="fi fi-rr-time-past"></i>
									<span v-if="processing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body" :hidden="!(tabmenu == 'notes')">
					<div class="row justify-content-end pt-3 pb-2" style="row-gap: .5rem;">
						<div class="col-auto mr-auto">
							<div class="h4">Data Shipper</div>
						</div>
						<div class="col-auto">
							<button class="btn btn-outline-primary" type="button" @click="downloadXlsxNotes" :disabled="processing">
								<i class="fi fi-rr-file-download"></i> Download Catatan
							</button>
						</div>
						<div class="col-auto">
							<button class="btn btn-primary" type="button" @click="showDialogNotes()" :disabled="processing">
								<i class="fi fi-rr-plus"></i> Buat Catatan
							</button>
						</div>
					</div>

					<table class="table table-hover row-border" ref="tablenotes" id="tablenotes">
						<thead>
							<tr>
								<th>
									<input type="checkbox" ref="checkall" @click="selectAllNotes" />
								</th>
								<th>Catatan</th>
								<th>Tanggal Dibuat</th>
								<th>Note by</th>
								<th>Aksi</th>
							</tr>
						</thead>
						<tbody @click="handleClick">
						</tbody>
					</table>
					<div class="text-center" :class="{ 'd-none' : emptyNotes }">
						<img :src="getImage('empty')" class="w-25" />
						<div class="h5 font-weight-bold">Catatan shipper kosong {{ emptyNotes }}</div>
						<p>Tidak ada riwayat perubahan pada rentang waktu ini atau pada filter yang kamu pilih</p>
					</div>
				</div>
				<div class="card-body" :hidden="!(tabmenu == 'logs')">
					<div class="h4 pt-3 pb-2">Riwayat Perubahan</div>
					<table class="table table-hover row-border" ref="tablelogs" id="tablelogs">
						<thead>
							<tr>
								<th>Jenis Perubahan</th>
								<th>Tanggal Perubahan</th>
								<th>Approved By</th>
								<th>Sebelum Perubahan</th>
								<th>Setelah Perubahan</th>
							</tr>
						</thead>
						<tbody>
						</tbody>
					</table>
					<div class="text-center" :class="{ 'd-none' : emptyLogs }">
						<img :src="getImage('empty')" class="w-25" />
						<div class="h5 font-weight-bold">Riwayat perubahan kosong {{ emptyLogs }}</div>
						<p>Tidak ada riwayat perubahan pada rentang waktu ini atau pada filter yang kamu pilih</p>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" tabindex="-1" role="dialog" ref="imageDialog" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{ modalTitle }}</h4>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<img :src="form_ktp.image_ktp" :class="{ 'd-none' : !modalImage }" alt="Shipper belum verifikasi" class="w-100">
						<img :src="form_ktp.image_selfie" :class="{ 'd-none' : modalImage }" alt="Shipper belum verifikasi" class="w-100">
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" tabindex="-1" role="dialog" ref="templateDialog" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">Model Foto</h4>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body p-3">
						<div class="row justify-content-center">
							<div class="col-sm-4 p-2">
								<div class="text-center bg-light border rounded">
									<div class="p-2">
										<img :src="getImage('model-1')" class="d-block w-100 p-1">
									</div>
									<label class="h5 font-weight-normal">Model 1</label>
								</div>
							</div>
							<div class="col-sm-4 p-2">
								<div class="text-center bg-light border rounded">
									<div class="p-2">
										<img :src="getImage('model-2')" class="d-block w-100 p-1">
									</div>
									<label class="h5 font-weight-normal">Model 2</label>
								</div>
							</div>
							<div class="col-sm-4 p-2">
								<div class="text-center bg-light border rounded">
									<div class="p-2">
										<img :src="getImage('model-3')" class="d-block w-100 p-1">
									</div>
									<label class="h5 font-weight-normal">Model 3</label>
								</div>
							</div>
							<div class="col-sm-4 p-2">
								<div class="text-center bg-light border rounded">
									<div class="p-2">
										<img :src="getImage('model-4')" class="d-block w-100 p-1">
									</div>
									<label class="h5 font-weight-normal">Model 4</label>
								</div>
							</div>
							<div class="col-sm-4 p-2">
								<div class="text-center bg-light border rounded">
									<div class="p-2">
										<img :src="getImage('model-5')" class="d-block w-100 p-1">
									</div>
									<label class="h5 font-weight-normal">Model 5</label>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" tabindex="-1" role="dialog" ref="noteDialog" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<form class="modal-content" @submit.prevent="submitNotes">
					<div class="modal-header">
						<h4 class="modal-title">Catatan Shipper</h4>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group">
							<div class="row">
								<div class="col">
									<label>Catatan Shipper</label>
								</div>
							</div>
							<textarea class="form-control" v-model="form_note.note" placeholder="Masukan catatan shipper" :class="{ 'is-invalid': false }"></textarea>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-primary" data-dismiss="modal">Batal</button>
						<button type="submit" class="btn btn-primary">Simpan Catatan</button>
					</div>
				</form>
			</div>
		</div>
	</section>
	<!-- /.content -->
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import $ from "jquery";
import vSelect from "vue-select";
import Swal from "sweetalert2";
import moment from "moment";
import "icheck-material";
import "vue-select/dist/vue-select.css";

export default {
	name: "DetailShipper",
	data() {
		return {
			user_id: 0,
			memberShip: {},
			errors: [],
			method: "",
			form: {
				id: "",
				percent_cashback: "",
				courier_id: "",
			},
			tabmenu: 'shipper',
			processing: false,
			form_shipper: {
				id: '',
				username: '',
				display_name: '',
				shipper_type: '',
				last_login: '',
				email: '',
				joined_on: '',
				kontak_hp: '',
				shipment_amount: '',
				bank_id: '',
				membership_type_id: '',
				bank_rekening: '',
				usercs_id: '',
				bank_atasnama: '',
				marking: '',
				marking_reason: '',
				alamat: '',
				active: false,
				courier_id: []
			},
			listBank: [],
			shipmentAmount: [
				{
					id: '5',
					label: '1 - 5'
				},
				{
					id: '30',
					label: '6 - 30'
				},
				{
					id: '100',
					label: '31 - 100'
				},
				{
					id: '1000',
					label: ' > 100'
				}
			],
			listMember: [],
			listCS: [],
			listMarking: [
				{
					id: 'blacklist',
					label: 'Blacklist',
					hidden: false
				},
				{
					id: 'suspect',
					label: 'Suspect',
					hidden: false
				},
				{
					id: 'trust',
					label: 'Trust',
					hidden: false
				},
				{
					id: 'suspect rekening',
					label: 'Auto suspect rekening',
					hidden: true
				}
			],
			listReason: [
				{
					id: '1',
					label: 'Terindikasi fraud'
				},
				{
					id: '2',
					label: 'Double account'
				}
			],
			listCourier: [],
			form_ktp: {
				nama: '',
				nik: '',
				nomor: '',
				email: '',
				ktp_alamat: '',
				image_ktp: '',
				image_selfie: ''
			},
			modalTitle: '',
			modalImage: '',
			form_request: {
				ktp_verify_created: '',
				ktp_verify: '',
				tanggal_signup: '',
				ktp_verify_on: '',
				admin: ''
			},
			history_request: [],
			reject_category: {},
			downloadNotes: [],
			filter_note: {},
			emptyNotes: false,
			form_note: {
				id: '',
				shipper_code: '',
				note: ''
			},
			// filter_log: {},
			emptyLogs: false,
		};
	},
	components: {
		vSelect,
	},
	created: function () {
		moment.locale('id');
		this.user_id = this.$route.params.id;
		this.loadShipper();
	},
	methods: {
		changeMenu(tipe) {
            $('.loading-overlay').addClass('show');
			if (this.tabmenu == tipe) {
				$('.loading-overlay').addClass('hide');
				return false;
			} else if (tipe == 'shipper') {
				this.loadShipper();
			} else if (tipe == 'verification') {
				this.loadVerification();
			} else if (tipe == 'notes') {
				this.loadNotes();
			} else if (tipe == 'logs') {
				this.loadLogs();
			} else {
				this.tabmenu = tipe;
			}
		},
		loadShipper: async function() {
			this.processing = true;

			authFetch("/shipper/shipper_warn/" + this.user_id).then((res) => {
				res.json().then((json) => {
					this.memberShip = json.data[0];
				});
			});

			authFetch("/shipper/bank").then(res => {
				res.json().then((json) => {
					this.listBank = json.data;
				});
			});

			await authFetch("/shipper/" + this.user_id).then((res) => {
				res.json().then((json) => {
					this.listCS = [{
						id: json.usercs_id,
						label: json.usercs_name
					}];

					// this.form_shipper = json;
					this.form_shipper.id = json.id;
					this.form_shipper.code = json.shipper_code;
					this.form_shipper.username = json.username;
					this.form_shipper.display_name = json.display_name;
					this.form_shipper.shipper_type = json.shipper_type;
					this.form_shipper.email = json.email;
					this.form_shipper.kontak_hp = json.kontak_hp;
					this.form_shipper.bank_id = json.bank_id;
					this.form_shipper.bank_rekening = json.bank_rekening;
					this.form_shipper.bank_atasnama = json.bank_atasnama;
					this.form_shipper.alamat = json.alamat;
					this.form_shipper.active = json.active == 1;
					this.form_shipper.last_login = json.last_login;
					this.form_shipper.joined_on = json.created_on;
					this.form_shipper.shipment_amount = json.shipment_amount;
					this.form_shipper.membership_type_id = json.membership_type_id;
					this.form_shipper.usercs_id = json.usercs_id;
					this.form_shipper.marking = json.marking;
					this.form_shipper.marking_reason = json.marking_reason;

					this.form_note.shipper_code = json.shipper_code;
					this.filter_note.shipper_code = json.shipper_code;
					
					this.form_ktp.nama = json.ktp_nama;
					this.form_ktp.nik = json.ktp_nik;
					this.form_ktp.nomor = json.ktp_nomor;
					this.form_ktp.email = json.ktp_email;
					this.form_ktp.ktp_alamat = json.ktp_alamat;
					this.form_ktp.image_ktp = json.ktp_foto;
					this.form_ktp.image_selfie = json.ktp_selfie;

					this.form_request.ktp_verify_created = json.ktp_verify_created;
					this.form_request.ktp_verify = json.ktp_verify;
					this.form_request.tanggal_signup = json.tanggal_signup;
					this.form_request.ktp_verify_on = json.ktp_verify_on;
					this.form_request.admin = json.admin;

					authFetch("/shipper/member_type/" + json.shipper_type).then(res => {
						res.json().then((json) => {
							this.listMember = json.data;
						});
					});
				});
			});

			await authFetch("/order/courier").then(res => {
				res.json().then((json) => {
					this.listCourier = json;
				});
			});
			
			authFetch("/order/courier/courier_setting/" + this.user_id).then(res => {
				res.json().then((json) => {
					this.form_shipper.courier_id = json;
				});
			});

			this.tabmenu = 'shipper';
			this.processing = false;
            $('.loading-overlay').removeClass('show');
		},
		getDate(time, hour = '') {
			return moment(time).format('DD/MM/YYYY ' + hour);
		},
		searchCS(search, loading) {
			if (search.length > 3) {
				loading(true);
				authFetch("/shipper/usercs?q=" + encodeURI(search)).then(res => {
					res.json().then(json => (this.listCS = json.data));
					loading(false);
				});
			} else {
				return false;
			}
		},
		submitShipper: function (ev) {
			this.processing = true;
			this.customSwal.fire({
				html: '<img src="' + this.getImage('edit') + '" class="d-block w-25 mx-auto mb-3" /><div class="h5">Yakin ingin menyimpan perubahan data shipper?</div>',
				showDenyButton: true,
				confirmButtonText: 'Simpan\u0020Perubahan',
				denyButtonText: 'Batal',
			}).then((result) => {
				if (result.isConfirmed) {
					authFetch('/shipper/' + this.form_shipper.id, {
						method: 'PUT',
						body: JSON.stringify({ group: this.form_shipper }),
					})
					.then((res) => {
						if (res.status === 201) {} else if (res.status === 400) {}
						return res.json();
					})
					.then((js) => {
						this.errors = [];
						if (!js.success) {
							console.log(js.details);

							for (var key in js.details) {
								if (js.details.hasOwnProperty(key)) {
									this.errors.push(js.details[key]);
								}
							}

							return;
						} else {
							this.customSwal.fire({
								html: '<img src="' + this.getImage('ok') + '" class="d-block w-75 mx-auto mb-3" /><div class="h5">Data shipper berhasil diubah.</div>',
								showConfirmButton: false,
								showCancelButton: true,
								cancelButtonText: 'Lanjutkan',
							});
						}
						this.processing = false;
					});
				} else {
					this.processing = false;
				}
			});
		},
		loadVerification: async function() {
			const self = this;
			await authFetch("/shipper/verification/groups").then((res) => {
				res.json().then((json) => {
					$.map(json.data, function (o) {
						self.reject_category[o.id] = o.label;
					});
				});
			});

			authFetch("/shipper/verification/" + this.form_shipper.id).then((res) => {
				res.json().then((json) => {
					if (json.success) {
						if (json.data == null) {
							// this.customSwal.fire({
							// 	html: '<div class="h5">Shipper belum melakukan verifikasi</div>',
							// 	showConfirmButton: false,
							// 	showCancelButton: true,
							// 	cancelButtonText: 'Lanjutkan'
							// })
						} else {
							this.form_ktp = json.data;
							if (json.data.ktp_verify == 2) {
								this.form_request = json.data_request;
							} else {
								this.form_request = json.history[0];
							}
							this.history_request = json.history;
						}
						this.tabmenu = 'verification';
						$('.loading-overlay').removeClass('show');
					}
				});
			});
		},
		statusKtp: function(status) {
			if (status == 1) {
				return {
					class: 'success',
					label: 'Approved'
				};
			} else if (status == 2) {
				return {
					class: 'warning',
					label: 'Requested'
				};
			} else if (status == 3) {
				return {
					class: 'danger',
					label: 'Rejected'
				};
			} else {
				return {
					class: 'secondary',
					label: 'Error'
				};
			}
		},
		getImage(file) {
			return require(`../assets/img/${file}.svg`); // the module request
		},
		showImage(title = '') {
			this.modalTitle = 'Foto ' + title + ' KTP';
			this.modalImage = title == '';
			$(this.$refs.imageDialog).modal("show");
		},
		showDialogTemplate() {
			$(this.$refs.templateDialog).modal("show");
		},
		approveVerification() {
			this.processing = true;
			this.form_request.verify = 1;

			this.customSwal.fire({
				html: '<img src="' + this.getImage('edit') + '" class="d-block w-25 mx-auto mb-3" /><div class="h5">Pastikan kembali data shipper sudah benar.<br>Apakah sudah yakin melakukan Approve?</div>',
				showDenyButton: true,
				confirmButtonText: 'Yakin',
				denyButtonText: 'Batal',
			}).then((result) => {
				if (result.isConfirmed) {
					authFetch("/shipper/verification/approval_ktp", {
						method: "PUT",
						body: Object.keys(this.form_request).map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(this.form_request[key])).join("&"),
					})
					.then((res) => {
						return res.json();
					})
					.then((js) => {
						if (js.success) {
							this.customSwal.fire("Proses berhasil", js.message, "success");
							this.loadVerification();
						} else {
							this.customSwalDelete.fire("Proses gagal", js.message, "error");
						}
						this.processing = false;
					});
				} else {
					this.processing = false;
				}
			});
		},
		rejectVerification: async function() {
			this.processing = true;

			const { value: rejectCategory } = await this.customSwal.fire({
				title: 'Pilih kategori reject',
				input: 'select',
				inputOptions: this.reject_category,
				inputPlaceholder: 'Pilih kategori reject',
				showDenyButton: true,
				confirmButtonText: 'Yakin',
				denyButtonText: 'Batal',
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (value > 0) {
							resolve();
						} else {
							resolve('Kategori reject belum dipilih');
						}
					});
				}
			});

			if (rejectCategory) {
				this.form_request.verify = 3;
				this.form_request.category = rejectCategory;

				authFetch("/shipper/verification/approval_ktp", {
					method: "PUT",
					body: Object.keys(this.form_request).map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(this.form_request[key])).join("&"),
				})
				.then((res) => {
					return res.json();
				})
				.then((js) => {
					if (js.success) {
						this.customSwal.fire("Proses berhasil", `${js.msg}`, "success");
						this.loadVerification();
					} else {
						this.customSwal.fire("Proses gagal", `${js.msg}`, "error");
					}
					this.processing = false;
				});
			} else {
				this.processing = false;
			}
		},
		loadNotes: function() {
			const e = this.$refs;
			$(e.noteDialog).modal("hide");

			if (this.tableNotes) {
				this.tableNotes.api().ajax.reload();
				this.tabmenu = 'notes';
				$('.loading-overlay').removeClass('show');
			} else {
				const self = this;
				this.tableNotes = createTable(e.tablenotes, {
					title: "Catatan Shipper",
					ajax: "/shipper/note_activity",
					frame: false,
					lengthChange: true,
					filter: true,
					filterBy: [1, 3],
					stateSave: true,
					processing: true,
					autoWidth: false,
					language: {
						processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'
					},
					paramData: function (d) {
						var filter = JSON.parse(JSON.stringify(self.filter_note));
						for (const key in filter) {
							d[key] = filter[key] === false ? 0 : filter[key];
						}
					},
					"order": [
						[2, 'desc']
					],
					columns: [
						{
							sortable: false,
							"data": "checkall", // can be null or undefined
							"defaultContent": '<input type="checkbox">',
							render: function (data, type, row, meta) {
								return (
									'<input type="checkbox" class="select-notes" value="' + row.id + '" />'
								);
							},
							visible: false
						},
						{
							data: "note"
						},
						{
							data: "created_on",
							render: function (data, type, row, meta) {
								return self.getDate(data, 'HH:mm');
							}
						},
						{
							data: "display_name"
						},
						{
							data: "id",
							render: function (data, type, row, meta) {
								return `<div class="btn-group" style="gap: 16px;">
											<div class="btn btn-link btn-lg p-0 text-warning"><i class="fi fi-rr-edit cursor-pointer note-edit" data-id="` + data + `" data-value="` + row.note + `"></i></div>
											<div class="btn btn-link btn-lg p-0 text-danger"><i class="fi fi-rr-trash cursor-pointer note-delete" data-id="` + data + `"></i></div>
										</div>`;
							},
						}
					],
					drawCallback: function (row, data) {
						self.emptyNotes = row._iRecordsTotal > 0;
						e.checkall.checked = false;
					},
					initComplete: function () {
						self.tabmenu = 'notes';
						$('.loading-overlay').removeClass('show');
					}
				});
			}
		},
		downloadXlsxNotes: function (e) {
			this.processing = true;
			var table = $('#tablenotes').DataTable();
			var length = table.page.info().recordsTotal;

			if (length > 20000) {
				Swal.fire("Proses gagal", `Maksimal Catatan Shipper sebanyak kurang lebih 20.000 data.`, "error");
				this.processing = false;
				return false;
			}

			var filter = JSON.parse(JSON.stringify(this.filter_note));
				filter.q = $("input[name=q]").val();
				filter.category = $("select[name=category]").val();

			console.log(filter);
			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");
			console.log(data)
			authFetch("/shipper/download_note_activity", {
				method: "POST",
				body: data + '&id=' + this.user_id,
			})
			.then((response) => {
				if (response.status === 201) {
				} else if (response.status === 400) {
				} else if (response.status === 500){
					Swal.fire("Timeout!", ``, "error");
					this.processing = false;
					this.msg = '';
					return false;
				}

				return response.blob();
			})
			.then((blob) => {
				setTimeout(() => {
					this.processing = false;
				}, 1000);
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement("a");
				a.href = url;
				a.download = "Catatan Shipper - " + this.form_shipper.display_name + ".xlsx";
				document.body.appendChild(a);
				a.click();
				a.remove();
			});
		},
		showDialogNotes: function(val = '') {
			if (val == '') {
				this.form_note.id = '';
			}
			this.form_note.note = val;
			$(this.$refs.noteDialog).modal("show");
		},
		submitNotes: function() {
			this.processing = false;
			authFetch('/shipper/note_activity', {
				method: this.form_note.id == '' ? 'POST' : 'PUT',
				body: Object.keys(this.form_note).map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(this.form_note[key])).join("&"),
			})
			.then((res) => {
				if (res.status === 201) {} else if (res.status === 400) {}
				return res.json();
			})
			.then((js) => {
				if (js.success) {
					this.customSwal.fire("Proses berhasil", `${js.message}`, "success");
					this.processing = false;
					this.loadNotes();
				} else {
					this.customSwal.fire("Proses gagal", `${js.message}`, "error");
				}
			});
		},
		selectAllNotes: function (e) {
			var val = e.target.checked;
			var checkboxes = document.querySelectorAll('.select-notes');
			for (var i = 0; i < checkboxes.length; i++) {
				var exp = checkboxes[i].value.split(';');
				this.downloadNotes = exp[1];
				checkboxes[i].checked = val;
			}

			if (val && checkboxes.length > 0) {
				this.selectedCount = checkboxes.length;
			} else {
				this.selectedCount = 0;
			}

			if (this.selectedCount > 0) {
				$(this.$refs.btnSelected).show();
			} else {
				$(this.$refs.btnSelected).hide();
			}
		},
		handleClick: async function(e) {
			if (e.target.matches(".note-edit")) {
				this.form_note.id = e.target.dataset.id;
				this.showDialogNotes(e.target.dataset.value);
			} else if (e.target.matches(".note-delete")) {
				this.processing = true;
				await this.customSwalDelete.fire({
					html: '<img src="' + this.getImage('delete') + '" class="d-block w-25 mx-auto mb-3" /><div class="h5">Yakin ingin menghapus catatan shipper?</div>',
					showDenyButton: true,
					confirmButtonText: 'Hapus\u0020Catatan',
					denyButtonText: 'Batal',
				}).then((result) => {
					if (result.isDenied) {
						this.processing = false;
						resolve(false);
					}
				});

				authFetch('/shipper/note_activity', {
					method: 'DELETE',
					body: 'id=' + e.target.dataset.id
				})
				.then((res) => {
					if (res.status === 201) {} else if (res.status === 400) {}
					return res.json();
				})
				.then((js) => {
					if (js.success) {
						this.customSwal.fire("Proses berhasil", `${js.message}`, "success");
						this.processing = false;
						this.loadNotes();
					} else {
						this.customSwal.fire("Proses gagal", `${js.message}`, "error");
					}
				});
			}
		},
		loadLogs: function() {
			if (this.tableLogs) {
				this.tableLogs.api().ajax.reload();
				this.tabmenu = 'logs';
				$('.loading-overlay').removeClass('show');
			} else {
				const e = this.$refs;
				const self = this;

				this.tableLogs = createTable(e.tablelogs, {
					title: "Riwayat Perubahan",
					ajax: "/shipper/history_shipper/" + this.user_id,
					frame: false,
					lengthChange: true,
					filter: false,
					filterBy: [],
					stateSave: true,
					processing: true,
					autoWidth: false,
					language: {
						processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'
					},
					paramData: function (d) {
						// var filter = JSON.parse(JSON.stringify(self.filter_log));
						// for (const key in filter) {
						// 	d[key] = filter[key] === false ? 0 : filter[key];
						// }
					},
					"order": [
						[1, 'desc']
					],
					columns: [
						{
							data: 'history',
							render: function (data, type, row, meta) {
								var col = '';
								for (let x of Object.keys(JSON.parse(data))) {
									col += x == 'tbl' ? '' : x[0].toUpperCase() + x.slice(1) + '<br>';
									/*
									"Kata Sandi":{"tbl":"users",%"password_hash":%
									*/
								}
								return col.substring(0, col.length - 4);
							}
						},
						{
							data: 'created_on',
							render: function (data, type, row, meta) {
								return self.getDate(data, '- HH:mm');
							}
						},
						{
							data: 'display_name'
						},
						{
							data: 'history',
							render: function (data, type, row, meta) {
								var col = '';
								var data = JSON.parse(data);
								for (let x of Object.keys(data)) {
									col += x == 'tbl' ? '' : data[x][0] + '<br>';
								}
								return col.substring(0, col.length - 4);
							}
						},
						{
							data: 'history',
							render: function (data, type, row, meta) {
								var col = '';
								var data = JSON.parse(data);
								for (let x of Object.keys(data)) {
									col += x == 'tbl' ? '' : data[x][1] + '<br>';
								}
								return col.substring(0, col.length - 4);
							}
						},
					],
					drawCallback: function (row, data) {
						self.emptyLogs = row._iRecordsTotal > 0;
					},
					initComplete: function () {
						self.tabmenu = 'logs';
						$('.loading-overlay').removeClass('show');
					}
				});
			}
		},
	},
	mounted() {
		this.customSwal = Swal.mixin({
			customClass: {
				content: 'p-0',
				popup: 'w-fit',
				title: 'h4 tex-dark',
				input: 'form-control',
				actions: 'd-flex justify-content-between flex-row-reverse',
				confirmButton: 'btn btn-primary w-49 font-weight-bold py-2',
				denyButton: 'btn btn-outline-primary w-49 font-weight-bold py-2',
				cancelButton: 'btn btn-primary btn-block font-weight-bold py-2',
			},
			buttonsStyling: false
		});
		this.customSwalDelete = Swal.mixin({
			customClass: {
				content: 'p-0',
				popup: 'w-fit',
				title: 'h4 tex-dark',
				input: 'form-control',
				actions: 'd-flex justify-content-between flex-row-reverse',
				confirmButton: 'btn btn-danger w-49 font-weight-bold py-2',
				denyButton: 'btn btn-outline-danger w-49 font-weight-bold py-2',
				cancelButton: 'btn btn-danger btn-block font-weight-bold py-2',
			},
			buttonsStyling: false
		});
	},
	watch: {}
};
</script>
<style>
:root {
	--bs-rows: 1;
	--bs-columns: 1;
	--bs-gap: 0;
}

@media (min-width: 576px) {
	.g-row-4 {
		--bs-rows: 4;
	}

	.g-row-6 {
		--bs-rows: 6;
	}

	.g-row-7 {
		--bs-rows: 7;
	}

	.g-row-8 {
		--bs-rows: 8;
	}

	.g-col-2 {
		--bs-columns: 2;
	}

	.g-col-12 {
		--bs-columns: 12
	}

	.gx-5 {
		grid-column: auto/span 5;
	}

	.gx-7 {
		grid-column: auto/span 7;
	}

	.gy-2 {
		grid-row: auto/span 2;
	}
}

@media (max-width: 575.98px) {
	.grid-auto-flow-column {
		grid-auto-flow: row;
	}
}

.nav .nav-item:has(.nav-link:not(.active)):after {
	content: '';
	display: block;
	height: 1px;
	width: 0px;
	background: #ff7203;
	transition: all .30s linear;
}

.nav .nav-item:has(.nav-link.active):after {
	content: '';
	display: block;
	height: 1px;
	width: 100%;
	background: #ff7203;
	transition: all .30s linear;
}

.custom-tab div:has(.nav-link.active):after {
	margin-left: auto;
	margin-right: auto;
}

.redesign textarea.form-control, .redesign div.form-control {
    padding: 0.75rem;
	/* height: fit-content; */
	/* align-content: center; */
}

.redesign [class*="icheck-material"] > input:first-child {
	bottom: unset;
	margin-top: 12px;
}

.vs__dropdown-toggle {
    /* display: block; */
    /* width: 100%; */
    min-height: calc(1.79rem + 4px);
	height: fit-content;
    padding: 0.75rem;
	align-items: center;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.3;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 2px solid #ced4da;
    border-radius: 0.375rem;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.vs__dropdown-toggle:has(.vs__search:focus) {
	color: #495057;
	background-color: #ffffff;
	border-color: #f6bfa9;
	outline: 0;
	box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), none;
}

.vs__dropdown-toggle:has(.is-invalid) {
    border-color: #f44336;
    padding-right: 1.79rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f44336' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f44336' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.325em + 0.1875rem);
    background-size: calc(0.65em + 0.375rem) calc(0.65em + 0.375rem);
}

.vs__selected-options {
	height: fit-content;
	padding: 0;
	gap: 0.375rem;
}

.vs__selected {
	height: fit-content;
	margin: 0;
}

.vs__search, .vs__search:focus {
	margin: 0;
	padding: 0;
	line-height: normal;
}

.vs__spinner, .vs__spinner:after {
	height: 3em;
	width: 3em;
}

.top-50 {
    top: 50% !important;
}

.bottom-0 {
    bottom: 0 !important;
}

.start-50 {
    left: 50% !important;
}

.end-0 {
    right: 0 !important;
}

.translate-middle {
    transform: translate(-50%, -50%) !important;
}

.btn-success {
	background-color: hsla(148, 100%, 34%, 1);
	border-color: hsla(148, 100%, 34%, 1);
}

.btn-danger {
	background-color: hsla(0, 87%, 45%, 1);
	border-color: hsla(0, 87%, 45%, 1);
}

.btn-outline-danger {
    color: hsla(0, 87%, 45%, 1);
    border-color: hsla(0, 87%, 45%, 1);
}

.bg-grey {
	background-color: hsla(0, 0%, 96%, 1);
	border-color: hsla(0, 0%, 96%, 1);
}

.swal2-validation-message {
	margin: 0;
}
</style>